import React from "react";
import _get from "lodash.get";
import { graphql } from "gatsby";

import SimplePage from "../components/simple-page";

const SimplePageTemplate = (props) => (
  <SimplePage
    body={_get(props, "data.markdownRemark.body")}
    {..._get(props, "data.markdownRemark.frontmatter", {})}
  />
);

export default SimplePageTemplate;

export const SimplePageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...SimplePage
    }
  }
`;
