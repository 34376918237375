import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import Layout from "../components/layout";
import SEOBlock, { seoFields } from "../components/seo";
import HeroSimple from "../components/hero-simple";
import MarkdownBlock from "../components/markdown-block";

import * as S from "../components/markdown-block.styles";

const SimplePage = (data) => (
  <Layout>
    <SEOBlock {..._get(data, "seo")} titleTemplate="%s | TED@Work" />
    <HeroSimple title={_get(data, "title")} />
    <div css={[S.wrap, S.largeText]}>
      <MarkdownBlock content={_get(data, "body")} />
    </div>
  </Layout>
);

export default SimplePage;

export const SimplePageFragment = graphql`
  fragment SimplePage on MarkdownRemark {
    body: rawMarkdownBody
    frontmatter {
      slug
      title
    }
  }
`;

export const simplePageFields = [
  seoFields,
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Slug",
    name: "slug",
    widget: "string",
  },
  {
    label: "Body",
    name: "body",
    widget: "markdown",
  },
];
